import React, { FC, useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { BUTTON_TYPES } from '@savgroup-front-common/constants';
import { BaseBackResponsePagination } from '@savgroup-front-common/types';

import { SafeFormattedMessageWithoutSpread } from '../../formatters';
import { useIsNewUiEnabled } from '../../hooks/useIsNewUiEnabled';
import {
  ChevronFastLeftIcon,
  ChevronFastRightIcon,
  ChevronFirstIcon,
  ChevronLastIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '../../protons/icons';
import { Input } from '../Form';

import messages from './messages';
import {
  $ButtonGroupWrapper,
  $ListPaginationButton,
  $ListPaginationInputText,
  $ListPaginationInterval,
  $ListPaginationIntervalOnlyNumber,
  $ListPaginationIntervalWrapper,
  $ListPaginationPage,
  $ListPaginationWrapper,
  $PaginationPerPage,
  $Spacer,
} from './Pagination.styles';

interface PaginationProps {
  paginationInterval?: number[];
  onSelectInterval?: (interval: number) => void;
  onChangePage: (value: number) => void;
  pagination: BaseBackResponsePagination;
  dataTestId?: string;
}

const Pagination: FC<PaginationProps> = ({
  onChangePage,
  onSelectInterval,
  pagination,
  paginationInterval,
  dataTestId,
}) => {
  const isNewUiEnabled = useIsNewUiEnabled();

  const [currentPage, setCurrentPage] = useState<number>(
    Number(pagination.page),
  );
  const [pagesNumber, setPagesNumber] = useState<number>(
    Number(pagination.pageCount),
  );

  const formContext = useForm();
  const { control } = formContext;

  useEffect(() => {
    if (pagination.pageCount !== pagesNumber) {
      if (currentPage > pagination.pageCount) {
        setCurrentPage(pagination.pageCount);
        onChangePage(pagination.pageCount);
      }
      setPagesNumber(pagination.pageCount);
    }
  }, [
    pagesNumber,
    onChangePage,
    pagination.page,
    pagination.pageCount,
    currentPage,
  ]);

  const disablePrev = currentPage <= 1;
  const disableNext = currentPage >= pagesNumber;

  const handlePageChange = useCallback(
    (page: number) => {
      setCurrentPage(page);
      onChangePage(page);
    },
    [onChangePage],
  );

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const nbr = Number(event.target.value);

      if (nbr === 0) {
        return setCurrentPage(1);
      }

      if (currentPage && (nbr < 1 || nbr > pagesNumber)) {
        return event.preventDefault();
      }

      setCurrentPage(nbr);

      return onChangePage(nbr);
    },
    [onChangePage, pagesNumber, currentPage],
  );

  const filteredPaginationInterval =
    paginationInterval?.filter((interval) => pagination.total >= interval) ??
    [];

  return (
    <$ListPaginationWrapper>
      <$ListPaginationIntervalWrapper>
        {filteredPaginationInterval?.length > 0 &&
          filteredPaginationInterval.map((interval) => (
            <$ListPaginationInterval
              key={interval}
              $isNewUiEnabled={isNewUiEnabled}
              onClick={() => {
                if (onSelectInterval) {
                  onSelectInterval(interval);
                }
              }}
              $isActive={interval === pagination.pageSize}
              data-testId={`${dataTestId}_listPaginationInterval_${interval}`}
            >
              {interval}
            </$ListPaginationInterval>
          ))}
      </$ListPaginationIntervalWrapper>
      <div data-testId={`${dataTestId}_listPaginationInterval_result_number`}>
        {pagination.total <= pagination.pageSize && (
          <$ListPaginationIntervalOnlyNumber>
            {pagination.total}{' '}
          </$ListPaginationIntervalOnlyNumber>
        )}

        {pagination.total > 1 && (
          <$PaginationPerPage $isNewUiEnabled={isNewUiEnabled}>
            <SafeFormattedMessageWithoutSpread
              message={messages.paginationPerPage}
            />
          </$PaginationPerPage>
        )}

        {pagination.total <= 1 && (
          <$PaginationPerPage $isNewUiEnabled={isNewUiEnabled}>
            <SafeFormattedMessageWithoutSpread
              message={messages.paginationPerPageSingular}
            />
          </$PaginationPerPage>
        )}
      </div>

      <$Spacer />

      {pagesNumber > 1 && (
        <$ListPaginationPage
          $isNewUiEnabled={isNewUiEnabled}
          data-testId={`${dataTestId}_listPaginationPage_container`}
        >
          <Controller
            name="paginationValue"
            control={control}
            render={({ field }) => (
              <Input
                name={field.name}
                min={0}
                max={pagination.total}
                isDisabled={pagesNumber === 1}
                onChange={handleChange}
                value={currentPage}
                selectAllOnFocus
                data-testId={`${dataTestId}_paginationValue`}
              />
            )}
          />

          <$ListPaginationInputText
            $isNewUiEnabled={isNewUiEnabled}
            data-testId={`${dataTestId}_listPaginationInputText_pagesNumber`}
          >
            <SafeFormattedMessageWithoutSpread
              message={messages.totalPage}
              values={{ totalPage: pagesNumber }}
            />
          </$ListPaginationInputText>

          <$ButtonGroupWrapper>
            {!disablePrev && (
              <$ListPaginationButton
                type={BUTTON_TYPES.BUTTON}
                $isNewUiEnabled={isNewUiEnabled}
                small
                hollow
                onClick={() => handlePageChange(1)}
                dataTestId={`${dataTestId}_first_page`}
              >
                {isNewUiEnabled ? (
                  <ChevronFastLeftIcon />
                ) : (
                  <ChevronFirstIcon />
                )}
              </$ListPaginationButton>
            )}
            <$ListPaginationButton
              type={BUTTON_TYPES.BUTTON}
              $isNewUiEnabled={isNewUiEnabled}
              small
              hollow
              disabled={disablePrev}
              onClick={() => {
                if (currentPage >= 2) {
                  handlePageChange(currentPage - 1);
                }
              }}
              dataTestId={`${dataTestId}_previous_page`}
            >
              <ChevronLeftIcon size="20px" />
            </$ListPaginationButton>

            <$ListPaginationButton
              type={BUTTON_TYPES.BUTTON}
              $isNewUiEnabled={isNewUiEnabled}
              small
              hollow
              disabled={disableNext}
              onClick={() => {
                if (currentPage < pagesNumber) {
                  handlePageChange(currentPage + 1);
                }
              }}
              dataTestId={`${dataTestId}_next_page`}
            >
              <ChevronRightIcon size="20px" />
            </$ListPaginationButton>
            {!disableNext && (
              <$ListPaginationButton
                type={BUTTON_TYPES.BUTTON}
                $isNewUiEnabled={isNewUiEnabled}
                small
                hollow
                onClick={() => handlePageChange(pagesNumber)}
                dataTestId={`${dataTestId}_last_page`}
              >
                {isNewUiEnabled ? (
                  <ChevronFastRightIcon />
                ) : (
                  <ChevronLastIcon />
                )}
              </$ListPaginationButton>
            )}
          </$ButtonGroupWrapper>
        </$ListPaginationPage>
      )}
    </$ListPaginationWrapper>
  );
};

Pagination.displayName = 'Pagination';

export default Pagination;
