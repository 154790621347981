import React, { ReactElement } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { MessageType } from '@savgroup-front-common/types';

import { Modal, MODAL_SIZES } from '../../../../atoms/modal';
import ConfirmModalContent from '../../../../molecules/ConfirmModal/ConfirmModalContent';
import { QuotationPendingCustomerValidationForm } from '../QuotationPendingCustomerValidation.types';

import ConfirmQuotationValidationBusinessCustomerTypeContent from './ConfirmQuotationValidationBusinessCustomerTypeContent';

interface ConfirmQuotationValidationBusinessCustomerTypeProps {
  isOpen: boolean;
  title: ReactElement | MessageType;
  didactic: ReactElement | MessageType;
  cancelMessage: MessageType;
  confirmMessage: MessageType;
  isLoading?: boolean;
  isDisabled?: boolean;
  isDanger?: boolean;
  size?: MODAL_SIZES;
  onCancel: () => void;
  onConfirm: () => void;
  formContext: UseFormReturn<QuotationPendingCustomerValidationForm>;
}

const ConfirmQuotationValidationBusinessCustomerType: React.FC<
  ConfirmQuotationValidationBusinessCustomerTypeProps
> = ({
  title,
  didactic,
  cancelMessage,
  confirmMessage,
  isLoading = false,
  isDanger = false,
  isDisabled = false,
  isOpen,
  size = MODAL_SIZES.TINY,
  onCancel,
  onConfirm,
  formContext,
}) => {
  return (
    <Modal
      dataTestId="ConfirmQuotationValidationBusinessCustomerType"
      isOpen={isOpen}
      onClose={onCancel}
      size={size}
      shouldCloseOnDimmerClick
      showCrossButton
    >
      <ConfirmModalContent
        title={title}
        didactic={didactic}
        cancelMessage={cancelMessage}
        confirmMessage={confirmMessage}
        isLoading={isLoading}
        onCancel={onCancel}
        onConfirm={onConfirm}
        isDanger={isDanger}
        isDisabled={isDisabled}
      >
        <ConfirmQuotationValidationBusinessCustomerTypeContent
          formContext={formContext}
        />
      </ConfirmModalContent>
    </Modal>
  );
};

ConfirmQuotationValidationBusinessCustomerType.displayName =
  'ConfirmQuotationValidationBusinessCustomerType';

export default ConfirmQuotationValidationBusinessCustomerType;
