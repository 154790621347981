import React, { FunctionComponent } from 'react';

import Icon from './Icon';
import { IconPropsInterface } from './Icon.types';

const ReversLogoIcon: FunctionComponent<IconPropsInterface> = ({
  color = 'black',
  size = '24px',
}) => (
  <Icon size={size} viewBox="0 0 21 30" color={color}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        transform="translate(0.000000, 5.217391)"
        d="M10.1538937,14.0038696 C10.671675,13.458 10.7038313,12.5384348 10.225425,11.9586522 L0.87058125,0.620608696 C0.392175,0.040826087 -0.0002625,0.17973913 -0.0002625,0.929086957 L-0.0002625,23.3260435 C-0.0002625,24.0747391 0.42433125,24.2423478 0.9421125,23.6977826 L10.1538937,14.0038696 Z"
        id="Fill-20"
        fill="currentColor"
      />
      <path
        transform="translate(6.562500, 22.173913)"
        d="M8.42867812,0.777586957 C7.95027188,0.197804348 7.13652187,0.169108696 6.61874063,0.713673913 L0.803709375,6.83693478 C0.285928125,7.3815 0.478209375,7.82628261 1.23158438,7.82628261 L12.8721469,7.82628261 C13.6261781,7.82628261 13.8545531,7.35280435 13.3761469,6.77302174 L8.42867812,0.777586957 Z"
        id="Fill-23"
        fill="currentColor"
      />
      <path
        transform="translate(3.281250, 0.000000)"
        d="M0.79340625,2.32141304 C0.506625,1.97445652 0.62803125,1.59293478 1.06378125,1.47423913 C1.06378125,1.47423913 11.8111875,-1.4448913 15.6345,3.18358696 C19.459125,7.81271739 14.4939375,17.7198913 14.4939375,17.7198913 C14.2924687,18.123587 13.8928125,18.1666304 13.605375,17.8196739 L0.79340625,2.32141304 Z"
        id="Fill-26"
        fill="currentColor"
      />
    </g>
  </Icon>
);

ReversLogoIcon.displayName = 'ReversLogoIcon';

export default ReversLogoIcon;
