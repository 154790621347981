import { ACTOR_TYPES, ActorTypes } from '@savgroup-front-common/constants';

import { AddressInfoDto } from './AddressInfo';
import { MARKETING_OFFER_TYPE } from './CommercialSolution';
import { PartnerScenarioTypes } from './PartnerScenarioType';
import { TechnicalIntervention } from './TechnicalIntervention';
import { UserAssigned } from './User';
import { WorkflowSummary } from './Workflow';

export type FileId = string;

export interface FileHighlights {
  FileReference?: string[];
  ShortFileReference?: string[];
  OwnerFirstName?: string[];
  OwnerLastName?: string[];
  OrderReference?: string[];
  Seller?: string[];
  TransportTrackingNumbers?: string[];
  OwnerZipCode?: string[];
  OwnerPhoneNumber?: string[];
  OwnerPhoneNumbers?: string[];
  OwnerMail?: string[];
  Brands?: string[];
  RmaNumber?: string[];
}

export interface File {
  brand: string;
  actorName: string;
  fileId: FileId;
  fileLanguage: string;
  fileProducts: FileProductSummary[];
  fileReference: string;
  highlights: FileHighlights;
  lastStateModification: string;
  localizedSolutionType?: string;
  localizedIssue?: string;

  issueName?: string;
  modelType: string;
  orderReference: string;
  ownerFirstName: string;
  ownerLastName: string;
  ownerMail: string;
  ownerPhoneNumber: string;
  ownerZipCode: string;
  seller: string;
  sellerId: string;
  solutionGroupType: string;
  solutionTypeName?: string;
  statusId: string[];
  storeName: string;
  warrantyType: string;
}

export enum FILE_ALERT_PRIORITIES {
  P0 = 'P0',
  P1 = 'P1',
}

export enum FILE_ALERT_TYPE {
  TRANSPORT_DELAYED = 'TransportDelayed',
  TRANSPORT_REFUSED_PARCEL = 'TransportRefusedParcel',
  TRANSPORT_WAITING_FOR_INFORMATIONS = 'TransportWaitingForInformations',
  TRANSPORT_WAITING_FOR_PICK_UP = 'TransportWaitingForPickUp',
  TRANSPORT_BACK_TO_SENDER = 'TransportBackToSender',
  REPAIRER_DELAY = 'RepairerDelay',
  REPAIRER_WAITING_FOR_SPARE_PARTS = 'RepairerWaitingForSpareParts',
  MISSING_ACTION = 'MissingAction',
}
export interface FileAlert extends File {
  priorities: FILE_ALERT_PRIORITIES[];
  alertId: string;
  alertType: FILE_ALERT_TYPE;
}

export type AdditionalInformation = {
  additionalInformationId: string;
  additionalInformationStringValue: string;
  additionalInformationEnumValue?: string;
  additionalInformationFilesValue?: {
    blobName: string;
    customerVisibility: boolean;
    isUploadedByOwner: boolean;
    uploadDateUtc: string;
    uploaderFullName: string;
    uploaderId: string;
    fileName: string;
    url: string;
  }[];
  additionalInformationFileValue?: {
    fileName: string;
    url: string;
  };
};

export interface AssemblingProducts {
  brandId: string;
  productAdequacy: ProductAdequacy;
  productOrientationName: string;
  productOutcome: ProductOutcome;
  productState: SellerProductState;
  productWeightInKg: number;
  sku: string;
  orderLineReference: string;
  isCombinable: boolean;
}

export interface FileUnexpectedProduct {
  orderLineReference: string;
  modelTypeId: string;
  brandId: string;
  modelId: string;
  productAdequacy: ProductAdequacy;
  productOutcome: ProductOutcome;
  productOrientationName: string;
  sku: string;
  assemblingProducts: Omit<AssemblingProducts[], 'productWeightInKg'>;
  isCombinable: boolean;
}

export enum SellerProductState {
  UNKNOWN = 'Unknown',
  NEW = 'New',
  USED = 'Used',
  RECONDITIONED = 'Reconditioned',
}

export enum HolderType {
  STORE = 'Store',
  REPAIRER_WORKSHOP = 'RepairerWorkshop',
  REPAIRER_PARTNER = 'RepairerPartner',
  REPAIRER_HUB = 'RepairerHub',
  SUPPLIER = 'Supplier',
  OWNER = 'Owner',
  CARRIER = 'Carrier',
  EXTERNAL_CARRIER = 'ExternalCarrier',
  WAREHOUSE = 'Warehouse',
}

export enum ProductAdequacy {
  NOT_SET = 'NotSet',
  IS_AS_EXPECTED = 'IsAsExpected',
  IS_BROKEN = 'IsBroken',
  IS_NOT_AS_EXPECTED = 'IsNotAsExpected',
  IS_MISSING = 'IsMissing',
  IS_CUSTOMIZED = 'Customized',
  IS_MULTIPLE_QUALIFICATION = 'MultipleQualification',
}

export enum ProductOutcome {
  NOT_SET = 'NotSet',
  DESTROYED = 'Destroyed',
  REFUND_BY_SUPPLIER = 'RefundedBySupplier',
  GIVEN_FOR_DESTOCKING = 'GivenForDestocking',
  DONATED = 'Donated',
  INTEGRATED_FOR_SECOND_LIFE = 'IntegratedForSecondLife',
  TRANSFERRED_TO_ANOTHER_ENTITY = 'TransferredToAnotherEntity',
  LOST = 'Lost',
  RETURN_BY_CARRIER = 'ReturnedByCarrier',
  ABANDONED = 'Abandoned',
  REFURBISHED = 'Refurbished',
  INTEGRATED_FOR_NEW = 'IntegratedForNew',
  REPAIR_BEFORE_RESTOCKING = 'RepairBeforeRestocking',
  CUSTOMIZED = 'Customized',
  REPAIR_WITHOUT_SPARE_PART = 'RepairedWithoutSparePart',
  REPAIRED_WITH_SPARE_PART = 'RepairedWithSparePart',
  RETURNED_AS_IS = 'ReturnedAsIs',
}

export interface Holder {
  holderId: string;
  holderName: string;
  holderStartingDate: string;
  holderType: HolderType;
}

export interface FileProductSummary {
  orderLineReference: string;
  modelTypeId: string;
  ownerProductId: string;
  brandId: string;
  modelId: string;
  claimId: string;
  issueId: string;
  reasonId: string;
  reasonComment: string;
  solutionTypeId: string;
  warrantyTypeId: string;
  supplierId: string;
  imei: string;
  serialNumber: string;
  productState: SellerProductState;
  productWeightInKg: number;
  isModelCumbersome: boolean;
  additionalInformation?: AdditionalInformation[];
  fileAdditionalInformation?: AdditionalInformation[];
  rmaStatus: string;
  rmaInfos: {
    key: string;
    value: string;
  }[];
  numRmaDemand: string;
  rmaMessages: {
    value: string;
    datetime: string;
    type: string;
  }[];
  holder: Holder;
  assemblingProducts?: AssemblingProducts[];
  sku: string;
  productAdequacy: ProductAdequacy;
  productOrientationName: string;
  isCombinable: boolean;
  productOutcome?: ProductOutcome;
  transportAdequacy?: ProductAdequacy;
  modelDisplayName?: string;
  brandDisplayName: string;
  factoryCode?: string;
}

export interface FileSummary {
  fileProducts: FileProductSummary[];
  fileUnexpectedProducts?: FileUnexpectedProduct[];
  orderLineReference: string;
  ownerProductId: string;
  modelId: string;
  modelTypeId: string;
  soldWarrantyId: string;
  claimInfoSummary: {
    claimId: string;
    claimGroupId: string;
    issueId: string;
    reasonId: string;
    solutionId: string;
    warrantyTypeId: string;
  };
  serialNumber: string;
  productState: string;
  imei: string;
  rmaAgreementNumbers?: string[];
  devicePassword: string;
  solutionGroupType: string;
  warrantyTypeId: string;
  issueId: string;
  reasonId: string;
  solutionId: string;
  ownerId: string;
  fileId: string;
  fileReference: string;
  orderReference: string;
  orderId: string;
  tenantId: string;
  sellerId: string;
  sellerName: string;
  fileType: string;
  currentState: {
    name: string;
    shortLabel: string;
    label: string;
  };
  moduleSummary: {
    id: string;
    definitionId: string;
    referencedModuleType: string;
    wave: number;
    parameters: Record<string, string | unknown>;
  };
  workflowSummary: WorkflowSummary;
  transportDepositSummary: {
    carrierProductId: string;
    type: string;
    reach: string;
    name: string;
    address: string;
  };
  transportDeliverySummary: {
    carrierProductId: string;
    type: string;
    reach: string;
    name: string;
    address: string;
  };
  marketPlace: string;
  buyDate: string;
  createdDate: string;
  endDate: string;
  trackingNumbers: {
    moduleDefinitionId: string;
    moduleInstanceId: string;
    trackingNumber: string;
    trackingUrl: string;
    carrier: string;
    externalCarrier: string;
    handledExternally: boolean;
    unknownCarrierName: string;
    handlingDirection: string;
    wave: number;
  }[];
  homePickupInfos: {
    cancelled: boolean;
    pickupRequestNumber: string;
    startTimeInLocalRecipientTimezone: string;
    endTimeInLocalRecipientTimezone: string;
    carrierService: string;
    carrierLabelId: string;
    moduleKind: string;
  }[];
  paymentBehavior: string;
  productLocationCountryCode: string;
  commercialSolution: {
    marketingOfferType?: MARKETING_OFFER_TYPE;
    solutionType: string;
    percentageValue: number;
    refundedAmount: {
      amount: number;
      currency: string;
    };
    changeTimeUtc: string;
  };
  fileLanguage: string;
  actorId: string;
  actorType: ACTOR_TYPES;
  mayNotContactCustomer: boolean;
  purchaseStoreId: string;
  depositStoreId?: string;
  storeActorId: string;
  customStateKeyValues: {
    key: string;
    value: string;
  }[];
  fileCreator: {
    id: string;
    type: string;
    userHasStoresAssigned: boolean;
  };
  attachedDocuments: {
    claimId: string;
    documentUrl: string;
    documentExtension: string;
    displayName: string;
    attachedDocumentType: string;
  }[];
  supplierId: string;
  siteActorId?: string;
  siteActorType?: ActorTypes;
  technicalInterventions: TechnicalIntervention[];
  partnerScenarioType?: PartnerScenarioTypes;
  ownerNotificationEmail?: string;
  assignment?: {
    currentUser: UserAssigned;
    previousUsers: UserAssigned[];
  };
  fileOutcome?: FILE_OUTCOME;
  isRefunded?: boolean;
  hasVideoCallHistory: boolean;
  deliveryAddress: AddressInfoDto;
}

export enum FILE_OUTCOME {
  REFUNDED = 'REFUNDED',
  NOT_REFUNDED = 'NOT_REFUNDED',
}

export interface FileProductFileShortSummary {
  orderLineReference: string;
  modelTypeId: string;
  ownerProductId: string;
  brandId: string;
  modelId: string;
  claimId: string;
  issueId: string;
  reasonId: string;
  reasonComment: string;
  solutionTypeId: string;
  warrantyTypeId: string;
  label: string;
  supplierId: string;
  imei: string;
  serialNumber: string;
  productState: string;
  productWeightInKg: number;
  isModelCumbersome: boolean;
  additionalInformation?: AdditionalInformation[];
  fileAdditionalInformation?: AdditionalInformation[];
  rmaStatus: string;
  rmaInfos: {
    key: string;
    value: string;
  }[];
  numRmaDemand: string;
  rmaMessages: {
    value: string;
    datetime: string;
    type: 'Sender';
  }[];
}

export interface FileShortSummary {
  fileProducts: FileProductFileShortSummary[];
  claimId: string;
  claimGroupId: string;
  productId: string;
  modelTypeId: string;
  modelId: string;
  brandId: string;
  modelWeightinKg: number;
  totalWeightInKg: number;
  tenantId: string;
  sellerId: string;
  ownerId: string;
  fileId: string;
  orderId: string;
  orderReference: string;
  actorId: string;
  actorType: ActorTypes;
  storeActorId: string;
  fileReference: string;
  createdDate: string;
  currentState: {
    module: {
      id: string;
      definitionId: string;
      wave: number;
      parameters: Record<string, unknown>;
    };
    currentState: {
      name: string;
      shortLabel: string;
      label: string;
    };
  };
  currentStatus: {
    name: string;
    shortLabel: string;
    label: string;
  };
  endDate: string;
  isClosed: boolean;
  language: string;
  isSplit: boolean;
  effectiveSolutionId: string;

  // TODO: @Grégoire => Demander au back de rajouter ce champ
  solutionName?: string;
}

export enum ReactionTypes {
  UNKNOWN = 'Unknown',
  INIT_MODULE = 'InitModule',
  NOTIFICATION = 'Notification',
  DELAYED_NOTIFICATION = 'DelayedNotification',
  INSTANCIATE_WORKFLOW = 'InstanciateWorkflow',
  COMMAND = 'Command',
  ALERT = 'Alert',
  DELAYED_ALERT = 'DelayedAlert',
}

export interface FileReactionHistorySummary {
  fileId: string;
  moduleId: string;
  actionId: string;
  executionDate: Date;
  actionRef: string;
  actionType: ReactionTypes;
  workFlowId: string;
  workflowVersion: number;
  actionParameters: {
    Recipient: string;
  };
}

export interface CustomerFileSummary extends FileSummary {
  fileUnexpectedProducts: [];
  totalWeightInKg: number;
  isClosed: boolean;
  safetyRisk: string;
  externalCaseId?: string;
  isAllReturned: boolean;
}
