import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import { QUOTATION_PAYMENT_TYPE } from '@savgroup-front-common/types';

import { Autocomplete, FormGroup } from '../../../../atoms/Form';
import { safeFormattedIntlString } from '../../../../formatters';
import messages from '../messages';
import { QuotationPendingCustomerValidationForm } from '../QuotationPendingCustomerValidation.types';

interface ConfirmQuotationValidationBusinessCustomerTypeContentProps {
  formContext: UseFormReturn<QuotationPendingCustomerValidationForm>;
}

const ConfirmQuotationValidationBusinessCustomerTypeContent: React.FC<
  ConfirmQuotationValidationBusinessCustomerTypeContentProps
> = ({ formContext }) => {
  const {
    control,
    formState: { errors },
  } = formContext;

  const paymentTypeOptions = [
    {
      label: safeFormattedIntlString(messages.paymentTypeModalLabelImmediate),
      value: QUOTATION_PAYMENT_TYPE.IMMEDIATE,
      data: QUOTATION_PAYMENT_TYPE.IMMEDIATE,
    },
    {
      label: safeFormattedIntlString(messages.paymentTypeModalLabelEndOfMonth),
      value: QUOTATION_PAYMENT_TYPE.END_OF_MONTH,
      data: QUOTATION_PAYMENT_TYPE.END_OF_MONTH,
    },
  ];

  return (
    <FormGroup dataTestId="dropdown-select-paymentType">
      <Controller
        control={control}
        name="paymentType"
        render={({ field }) => (
          <Autocomplete
            name={field.name}
            options={paymentTypeOptions}
            isRequired
            ref={field.ref}
            onChange={field.onChange}
            value={field.value}
            errors={errors}
            label={safeFormattedIntlString(messages.paymentTypeModalFormLabel)}
          />
        )}
      />
    </FormGroup>
  );
};

ConfirmQuotationValidationBusinessCustomerTypeContent.displayName =
  'ConfirmQuotationValidationBusinessCustomerTypeContent';

export default ConfirmQuotationValidationBusinessCustomerTypeContent;
