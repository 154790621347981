import { loadStripe, StripeElementLocale } from '@stripe/stripe-js';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { StripeConfiguration } from '@savgroup-front-common/configuration/src';
import { selectFirstClaim } from '@savgroup-front-common/core/src/domains/claims/selectors';
import { useMyAccountTypedSelector } from 'myaccount/hooks';

import { selectSellerIdFromFile } from '../../../../domains/Customer/selectors';
import { useGetSellerConfiguration } from '../../../app/hooks';

const useGetStripePromise = () => {
  const sellerIdFromFile = useMyAccountTypedSelector(selectSellerIdFromFile);
  const firstClaim = useMyAccountTypedSelector(selectFirstClaim);
  const intl = useIntl();

  const { sellerConfiguration } = useGetSellerConfiguration({
    sellerId: sellerIdFromFile || firstClaim?.sellerId,
  });

  const publicKey =
    sellerConfiguration?.stripePublishableKey || StripeConfiguration.publicKey;

  return useCallback(() => {
    return loadStripe(publicKey, {
      locale: intl.locale as StripeElementLocale,
    });
  }, [publicKey, intl.locale]);
};

export default useGetStripePromise;
