import React, { FunctionComponent, ReactElement } from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';

import { BUTTON_TYPES } from '@savgroup-front-common/constants/src/shared';
import { MessageType } from '@savgroup-front-common/types';

import { Heading } from '../../atoms/Heading';
import {
  SafeFormattedMessage,
  SafeFormattedMessageWithoutSpread,
} from '../../formatters';

import { $Button, $ConfirmFooter, $ConfirmHeader } from './ConfirmModal.styles';

interface ConfirmModalContentProps {
  isLoading: boolean;
  isDisabled: boolean;
  isDanger: boolean;
  title: ReactElement | MessageType;
  didactic: ReactElement | MessageType;
  cancelMessage: MessageType;
  confirmMessage: MessageType;
  onCancel: () => void;
  onConfirm: () => void;
}

const ConfirmModalContent: FunctionComponent<
  React.PropsWithChildren<ConfirmModalContentProps>
> = ({
  title,
  didactic,
  cancelMessage,
  confirmMessage,
  isLoading,
  onCancel,
  onConfirm,
  isDanger,
  isDisabled,
  children,
}) => {
  return (
    <>
      {title && (
        <$ConfirmHeader>
          <Heading level={2}>{SafeFormattedMessage(title)}</Heading>
        </$ConfirmHeader>
      )}
      {didactic && (
        <$ConfirmHeader>{SafeFormattedMessage(didactic)}</$ConfirmHeader>
      )}
      {children}
      <$ConfirmFooter>
        <Row>
          <Col>
            <$Button
              type={BUTTON_TYPES.BUTTON}
              primary
              hollow
              dataTestId="confirmModal_no"
              onClick={() => onCancel()}
            >
              <SafeFormattedMessageWithoutSpread message={cancelMessage} />
            </$Button>
          </Col>
          <Col>
            <$Button
              danger={isDanger}
              primary={!isDanger}
              disabled={isDisabled}
              type={BUTTON_TYPES.BUTTON}
              dataTestId="confirmModal_yes"
              onClick={() => onConfirm()}
              isLoading={isLoading}
            >
              <SafeFormattedMessageWithoutSpread message={confirmMessage} />
            </$Button>
          </Col>
        </Row>
      </$ConfirmFooter>
    </>
  );
};

ConfirmModalContent.displayName = 'ConfirmModalContent';

export default ConfirmModalContent;
