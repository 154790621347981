import React, { FunctionComponent } from 'react';

import { ProductImage } from '@savgroup-front-common/core/src/atoms/ProductImage';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';

import messages from '../../messages';
import { ProductsInfoMinimal } from '../../ProductsInfo.types';

import {
  $CardContentContainer,
  $CardContentDescription,
  $CardContentProductName,
  $CardContentSerialNumber,
  $CardContentThumbnail,
  $CardContentThumbnailWrapper,
} from './MonoProductInfoDescription.styles';

const MonoProductInfoDescription: FunctionComponent<{
  productInfo: ProductsInfoMinimal;
}> = ({ productInfo }) => {
  return (
    <$CardContentContainer>
      {((!productInfo.modelPictureUrl && productInfo.modelTypeId) ||
        productInfo.modelPictureUrl) && (
        <$CardContentThumbnailWrapper>
          <$CardContentThumbnail>
            <ProductImage pictureUrl={productInfo.modelPictureUrl} />
          </$CardContentThumbnail>
        </$CardContentThumbnailWrapper>
      )}
      <$CardContentDescription>
        <$CardContentProductName>
          {productInfo.displayName}
        </$CardContentProductName>
        {!!productInfo.serialNumber && (
          <$CardContentSerialNumber>
            <SafeFormattedMessageWithoutSpread
              message={messages.serialNumber}
              values={{
                serialNumber: productInfo?.serialNumber,
              }}
            />
          </$CardContentSerialNumber>
        )}
      </$CardContentDescription>
    </$CardContentContainer>
  );
};

MonoProductInfoDescription.displayName = 'MonoProductInfoDescription';
export default MonoProductInfoDescription;
