/* eslint-disable jsx-a11y/control-has-associated-label */
import md5 from 'md5';
import React, { FC, PropsWithChildren } from 'react';
import { useMedia } from 'react-use';

import { media, PERMISSIONS } from '@savgroup-front-common/constants';

import { SafeFormattedMessageWithoutSpread } from '../../../formatters';
import getSparePartConditionMessage from '../../../formatters/getSparePartConditionMessage';
import { formatReverseMoneyToString } from '../../../formatters/intl';
import { getRecipientTypeMessage } from '../../../helpers/i18n';
import { useHasPermission } from '../../../hooks';
import { AdaptedQuotationNewModel } from '../types/AdaptedQuotationNewModel';

import messages from './messages';
import { QuotationLine } from './QuotationLine';
import { QuotationLineHead } from './QuotationLineHead';
import { $AmountCell, $AmountHeaderCel } from './QuotationLines.styles';
import { QuotationLinesMobileView } from './QuotationLinesMobileView';

interface QuotationLineHeadProps {
  isActive?: boolean;
  quotation: AdaptedQuotationNewModel;
}

const QuotationLines: FC<PropsWithChildren<QuotationLineHeadProps>> = ({
  isActive = false,
  quotation,
}) => {
  const isMobileView = useMedia(media.maxWidth.sm);

  const services = [
    ...(quotation.services || []),
    ...(quotation.unknownServices || []),
    ...(quotation.administrativeFees || []),
  ];

  const optionalSpareParts = (quotation.sparePartLines || []).filter(
    (sparePart) =>
      !sparePart.isRequiredForRepair && sparePart.isSelectedForRepair,
  );
  const requiredSpareParts = (quotation.sparePartLines || []).filter(
    (sparePart) => sparePart.isRequiredForRepair,
  );

  const hasHideQuotationDisplaySparePartsReferencePermission = useHasPermission(
    PERMISSIONS.HIDE_QUOTATION_DISPLAY_SPARE_PARTS_REFERENCE,
  );

  if (isMobileView) {
    return (
      <QuotationLinesMobileView
        quotation={quotation}
        isActive={isActive}
        hasHideQuotationDisplaySparePartsReferencePermission={
          hasHideQuotationDisplaySparePartsReferencePermission
        }
      />
    );
  }

  return (
    <div>
      {services.length > 0 && (
        <QuotationLineHead
          isActive={isActive}
          gridTemplateColumns="8fr 2fr 2fr"
        >
          <div>
            <SafeFormattedMessageWithoutSpread message={messages.service} />
          </div>
          <div>
            <SafeFormattedMessageWithoutSpread message={messages.recipient} />
          </div>
          <$AmountHeaderCel>
            <SafeFormattedMessageWithoutSpread message={messages.total} />
          </$AmountHeaderCel>
        </QuotationLineHead>
      )}

      {(quotation.administrativeFees || []).map((service, index) => {
        return (
          <QuotationLine
            key={md5(formatReverseMoneyToString(service.amountIncludedTax))}
            isActive={isActive}
            gridTemplateColumns="8fr 2fr 2fr"
          >
            <div data-testid={`administrativeFees_description_${index}`}>
              {service.description}
            </div>
            <div data-testid={`services_recipient_${index}`}>
              {service?.recipient && (
                <SafeFormattedMessageWithoutSpread
                  message={getRecipientTypeMessage(
                    service.recipient?.recipientType,
                  )}
                />
              )}
            </div>
            <$AmountCell
              data-testid={`administrativeFees_amountIncludedTax_${index}`}
            >
              {formatReverseMoneyToString(service.amountIncludedTax)}
            </$AmountCell>
          </QuotationLine>
        );
      })}

      {(quotation.services || []).map((service, index) => {
        return (
          <QuotationLine
            key={service.id}
            isActive={isActive}
            gridTemplateColumns="8fr 2fr 2fr"
          >
            <div data-testid={`services_description_${index}`}>
              {service.description}
            </div>
            <div data-testid={`services_recipient_${index}`}>
              {service.recipient && (
                <SafeFormattedMessageWithoutSpread
                  message={getRecipientTypeMessage(
                    service.recipient?.recipientType,
                  )}
                />
              )}
            </div>
            <$AmountCell data-testid={`services_amountIncludedTax_${index}`}>
              {formatReverseMoneyToString(service.amountIncludedTax)}
            </$AmountCell>
          </QuotationLine>
        );
      })}

      {(quotation.unknownServices || []).map((service, index) => {
        return (
          <QuotationLine
            key={md5(formatReverseMoneyToString(service.amountIncludedTax))}
            isActive={isActive}
            gridTemplateColumns="8fr 2fr 2fr"
          >
            <div data-testid={`unknownServices_description_${index}`}>
              {service.description}
            </div>
            <div data-testid={`services_recipient_${index}`}>
              {service.recipient && (
                <SafeFormattedMessageWithoutSpread
                  message={getRecipientTypeMessage(
                    service.recipient?.recipientType,
                  )}
                />
              )}
            </div>
            <$AmountCell data-testid={`services_amountIncludedTax_${index}`}>
              {formatReverseMoneyToString(service.amountIncludedTax)}
            </$AmountCell>
          </QuotationLine>
        );
      })}
      {(quotation.unknownSpareParts || []).map((sparePart, index) => {
        return (
          <QuotationLine
            key={md5(formatReverseMoneyToString(sparePart.amountIncludedTax))}
            isActive={isActive}
            gridTemplateColumns="8fr 2fr 2fr"
          >
            <div data-testid={`unknownSpareParts_description_${index}`}>
              {sparePart.description}
            </div>
            <div data-testid={`services_recipient_${index}`}>
              {sparePart.recipient && (
                <SafeFormattedMessageWithoutSpread
                  message={getRecipientTypeMessage(
                    sparePart.recipient?.recipientType,
                  )}
                />
              )}
            </div>
            <$AmountCell data-testid={`services_amountIncludedTax_${index}`}>
              {formatReverseMoneyToString(sparePart.amountIncludedTax)}
            </$AmountCell>
          </QuotationLine>
        );
      })}

      {(quotation.discounts || []).map((discount, index) => {
        return (
          <QuotationLine
            key={md5(formatReverseMoneyToString(discount.amountIncludedTax))}
            isActive={isActive}
            gridTemplateColumns="8fr 2fr 2fr"
          >
            <div data-testid={`discounts_description_${index}`}>
              {discount.description}
            </div>
            <div data-testid={`services_recipient_${index}`}>
              {discount.recipient && (
                <SafeFormattedMessageWithoutSpread
                  message={getRecipientTypeMessage(
                    discount.recipient?.recipientType,
                  )}
                />
              )}
            </div>
            <$AmountCell data-testid={`services_amountIncludedTax_${index}`}>
              {formatReverseMoneyToString(discount.amountIncludedTax)}
            </$AmountCell>
          </QuotationLine>
        );
      })}

      {requiredSpareParts.length > 0 && (
        <QuotationLineHead
          isActive={isActive}
          gridTemplateColumns="4fr 4fr 2fr 2fr"
        >
          <div>
            <SafeFormattedMessageWithoutSpread
              message={messages.requiredSpareParts}
            />
          </div>
          <div>
            <SafeFormattedMessageWithoutSpread
              message={messages.statusSpareParts}
            />
          </div>
          <div>
            <SafeFormattedMessageWithoutSpread message={messages.recipient} />
          </div>
          <$AmountHeaderCel>
            <SafeFormattedMessageWithoutSpread message={messages.total} />
          </$AmountHeaderCel>
        </QuotationLineHead>
      )}
      {requiredSpareParts.map((sparePart, index) => {
        return (
          <QuotationLine
            key={sparePart.id}
            isActive={isActive}
            gridTemplateColumns="4fr 4fr 2fr 2fr"
          >
            <div
              data-testid={`requiredSpareParts_subCategory_supplierReference_${index}`}
            >
              {hasHideQuotationDisplaySparePartsReferencePermission
                ? sparePart.manufacturerLabel
                : [sparePart.supplierReference, sparePart.manufacturerLabel]
                    .filter((x) => !!x)
                    .join(' - ')}
            </div>
            <div data-testid={`requiredSpareParts_condition_${index}`}>
              {sparePart.condition && (
                <SafeFormattedMessageWithoutSpread
                  message={getSparePartConditionMessage(sparePart.condition)}
                />
              )}
            </div>
            <div data-testid={`requiredSpareParts_recipient_${index}`}>
              {sparePart.recipient?.data && (
                <SafeFormattedMessageWithoutSpread
                  message={getRecipientTypeMessage(sparePart.recipient?.data)}
                />
              )}
            </div>
            <$AmountCell
              data-testid={`requiredSpareParts_recommendedSalePriceIncludingTaxes_${index}`}
            >
              {formatReverseMoneyToString(
                sparePart.recommendedSalePriceIncludingTaxes,
              )}
            </$AmountCell>
          </QuotationLine>
        );
      })}

      {optionalSpareParts.length > 0 && (
        <QuotationLineHead gridTemplateColumns="4fr 4fr 2fr 2fr">
          <div>
            <SafeFormattedMessageWithoutSpread
              message={messages.optionalSpareParts}
            />
          </div>
          <div>
            <SafeFormattedMessageWithoutSpread
              message={messages.statusSpareParts}
            />
          </div>
          <div>
            <SafeFormattedMessageWithoutSpread message={messages.recipient} />
          </div>
          <$AmountHeaderCel>
            <SafeFormattedMessageWithoutSpread message={messages.total} />
          </$AmountHeaderCel>
        </QuotationLineHead>
      )}
      {optionalSpareParts.map((sparePart, index) => {
        return (
          <QuotationLine
            key={sparePart.id}
            isActive={isActive}
            gridTemplateColumns="4fr 4fr 2fr 2fr"
          >
            <div
              data-testid={`optionalSpareParts_subCategory_supplierReference_${index}`}
            >
              {hasHideQuotationDisplaySparePartsReferencePermission
                ? sparePart.manufacturerLabel
                : [sparePart.supplierReference, sparePart.manufacturerLabel]
                    .filter((x) => !!x)
                    .join(' - ')}
            </div>
            <div data-testid={`optionalSpareParts_condition_${index}`}>
              <SafeFormattedMessageWithoutSpread
                message={getSparePartConditionMessage(sparePart.condition)}
              />
            </div>
            <div data-testid={`optionalSpareParts_recipient_${index}`}>
              {sparePart.recipient?.data && (
                <SafeFormattedMessageWithoutSpread
                  message={getRecipientTypeMessage(sparePart.recipient?.data)}
                />
              )}
            </div>
            <$AmountCell
              data-testid={`optionalSpareParts_recommendedSalePriceIncludingTaxes_${index}`}
            >
              {formatReverseMoneyToString(
                sparePart.recommendedSalePriceIncludingTaxes,
              )}
            </$AmountCell>
          </QuotationLine>
        );
      })}
    </div>
  );
};

QuotationLines.displayName = 'QuotationLineHead';

export default QuotationLines;
