/* eslint-disable jsx-a11y/control-has-associated-label */
import md5 from 'md5';
import React, { FC } from 'react';
import { useMedia } from 'react-use';

import { media } from '@savgroup-front-common/constants';
import {
  SparePartQuotationLine,
  SparePartSearchSummary,
} from '@savgroup-front-common/types';

import { SafeFormattedMessageWithoutSpread } from '../../../../formatters';
import { formatReverseMoneyToString } from '../../../../formatters/intl';
import { getRecipientTypeMessage } from '../../../../helpers/i18n';
import {
  $AmountCell,
  $AmountHeaderCel,
  QuotationLine,
  QuotationLineHead,
} from '../../QuotationLines';
import messages from '../messages';
import {
  QuotationPendingCustomerValidationForm,
  QuotationPendingCustomerValidationNewModel,
} from '../QuotationPendingCustomerValidation.types';
import {
  SparePartQuotationPendingCustomerValidationLine,
  SparePartQuotationPendingCustomerValidationLineSkeleton,
} from '../SparePartQuotationPendingCustomerValidationLine';

import { QuotationPendingCustomerValidationContentMobileView } from './QuotationPendingCustomerValidationContentMobileView';

interface QuotationPendingCustomerValidationContentProps {
  adaptedQuotation: QuotationPendingCustomerValidationNewModel;
  requiredForRepaidSpareParts?: SparePartQuotationLine[];
  optionalSpareParts?: SparePartQuotationLine[];
  onSelectSparePartQuotationLineConditionChange: ({
    values,
    alternativeSpareParts,
  }: {
    values: QuotationPendingCustomerValidationForm;
    alternativeSpareParts: Record<
      string,
      Record<string, SparePartSearchSummary>
    >;
  }) => Promise<{ failure: boolean }>;
}

const QuotationPendingCustomerValidationContent: FC<
  QuotationPendingCustomerValidationContentProps
> = ({
  adaptedQuotation,
  requiredForRepaidSpareParts,
  optionalSpareParts,
  onSelectSparePartQuotationLineConditionChange,
}) => {
  const isMobileView = useMedia(media.maxWidth.sm);

  if (isMobileView) {
    return (
      <QuotationPendingCustomerValidationContentMobileView
        adaptedQuotation={adaptedQuotation}
        requiredForRepaidSpareParts={requiredForRepaidSpareParts}
        optionalSpareParts={optionalSpareParts}
        onSelectSparePartQuotationLineConditionChange={
          onSelectSparePartQuotationLineConditionChange
        }
      />
    );
  }

  return (
    <>
      <div>
        <QuotationLineHead isActive gridTemplateColumns="8fr 2fr 2fr">
          <div>
            <SafeFormattedMessageWithoutSpread message={messages.service} />
          </div>
          <div>
            <SafeFormattedMessageWithoutSpread message={messages.recipient} />
          </div>
          <$AmountHeaderCel>
            <SafeFormattedMessageWithoutSpread message={messages.total} />
          </$AmountHeaderCel>
        </QuotationLineHead>

        {(adaptedQuotation.administrativeFees || []).map((fee, index) => {
          return (
            <QuotationLine
              key={fee.preTaxUnitPrice.amount.toString()}
              isActive
              gridTemplateColumns="8fr 2fr 2fr"
            >
              <div data-testid={`administrativeFees_description_${index}`}>
                {fee.description}
              </div>
              <div data-testid={`services_recipient_${index}`}>
                {fee.recipient && (
                  <SafeFormattedMessageWithoutSpread
                    message={getRecipientTypeMessage(
                      fee.recipient?.recipientType,
                    )}
                  />
                )}
              </div>
              <$AmountCell
                data-testid={`administrativeFees_amountIncludedTax_${index}`}
              >
                {formatReverseMoneyToString(fee.amountIncludedTax)}
              </$AmountCell>
            </QuotationLine>
          );
        })}

        {(adaptedQuotation.services || []).map((service, index) => {
          return (
            <QuotationLine
              key={service.id}
              isActive
              gridTemplateColumns="8fr 2fr 2fr"
            >
              <div data-testid={`services_description_${index}`}>
                {service.description}
              </div>
              <div data-testid={`services_recipient_${index}`}>
                {service.recipient && (
                  <SafeFormattedMessageWithoutSpread
                    message={getRecipientTypeMessage(
                      service.recipient?.recipientType,
                    )}
                  />
                )}
              </div>
              <$AmountCell data-testid={`services_amountIncludedTax_${index}`}>
                {formatReverseMoneyToString(service.amountIncludedTax)}
              </$AmountCell>
            </QuotationLine>
          );
        })}
        {(adaptedQuotation.unknownServices || []).map(
          (unknownService, index) => {
            return (
              <QuotationLine
                key={md5(
                  formatReverseMoneyToString(unknownService.amountIncludedTax),
                )}
                isActive
                gridTemplateColumns="8fr 2fr 2fr"
              >
                <div data-testid={`unknownServices_description_${index}`}>
                  {unknownService.description}
                </div>
                <div data-testid={`services_recipient_${index}`}>
                  {unknownService.recipient && (
                    <SafeFormattedMessageWithoutSpread
                      message={getRecipientTypeMessage(
                        unknownService.recipient?.recipientType,
                      )}
                    />
                  )}
                </div>
                <$AmountCell
                  data-testid={`services_amountIncludedTax_${index}`}
                >
                  {formatReverseMoneyToString(unknownService.amountIncludedTax)}
                </$AmountCell>
              </QuotationLine>
            );
          },
        )}

        {(adaptedQuotation.unknownSpareParts || []).map((sparePart, index) => {
          return (
            <QuotationLine
              key={md5(formatReverseMoneyToString(sparePart.amountIncludedTax))}
              isActive
              gridTemplateColumns="8fr 2fr 2fr"
            >
              <div data-testid={`unknownSpareParts_description_${index}`}>
                {sparePart.description}
              </div>
              <div data-testid={`services_recipient_${index}`}>
                {sparePart.recipient && (
                  <SafeFormattedMessageWithoutSpread
                    message={getRecipientTypeMessage(
                      sparePart.recipient?.recipientType,
                    )}
                  />
                )}
              </div>
              <$AmountCell data-testid={`services_amountIncludedTax_${index}`}>
                {formatReverseMoneyToString(sparePart.amountIncludedTax)}
              </$AmountCell>
            </QuotationLine>
          );
        })}

        {(adaptedQuotation.discounts || []).map((discount, index) => {
          return (
            <QuotationLine
              key={md5(formatReverseMoneyToString(discount.amountIncludedTax))}
              isActive
              gridTemplateColumns="8fr 2fr 2fr"
            >
              <div data-testid={`discounts_description_${index}`}>
                {discount.description}
              </div>
              <div data-testid={`services_recipient_${index}`}>
                {discount.recipient?.recipientType && (
                  <SafeFormattedMessageWithoutSpread
                    message={getRecipientTypeMessage(
                      discount.recipient?.recipientType,
                    )}
                  />
                )}
              </div>
              <$AmountCell data-testid={`services_amountIncludedTax_${index}`}>
                {formatReverseMoneyToString(discount.amountIncludedTax)}
              </$AmountCell>
            </QuotationLine>
          );
        })}

        {!requiredForRepaidSpareParts ||
          (!optionalSpareParts && (
            <SparePartQuotationPendingCustomerValidationLineSkeleton />
          ))}

        {requiredForRepaidSpareParts &&
          requiredForRepaidSpareParts.length > 0 && (
            <>
              <QuotationLineHead isActive gridTemplateColumns="4fr 4fr 2fr 2fr">
                <div>
                  <SafeFormattedMessageWithoutSpread
                    message={messages.requiredSpareParts}
                  />
                </div>
                <div>
                  <SafeFormattedMessageWithoutSpread
                    message={messages.statusSpareParts}
                  />
                </div>
                <div>
                  <SafeFormattedMessageWithoutSpread
                    message={messages.recipient}
                  />
                </div>
                <$AmountHeaderCel>
                  <SafeFormattedMessageWithoutSpread message={messages.total} />
                </$AmountHeaderCel>
              </QuotationLineHead>

              {requiredForRepaidSpareParts.map(
                (sparePartQuotationLine, index) => {
                  return (
                    <SparePartQuotationPendingCustomerValidationLine
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      onSelectSparePartQuotationLineChange={
                        onSelectSparePartQuotationLineConditionChange
                      }
                      sparePartQuotationLine={sparePartQuotationLine}
                      index={index}
                      preDataTestId="requiredSpareParts"
                    />
                  );
                },
              )}
            </>
          )}

        {optionalSpareParts && optionalSpareParts.length > 0 && (
          <>
            <QuotationLineHead gridTemplateColumns="4fr 4fr 2fr 2fr">
              <div>
                <SafeFormattedMessageWithoutSpread
                  message={messages.optionalSpareParts}
                />
              </div>
              <div>
                <SafeFormattedMessageWithoutSpread
                  message={messages.statusSpareParts}
                />
              </div>
              <div>
                <SafeFormattedMessageWithoutSpread
                  message={messages.recipient}
                />
              </div>
              <$AmountHeaderCel>
                <SafeFormattedMessageWithoutSpread message={messages.total} />
              </$AmountHeaderCel>
            </QuotationLineHead>
            {optionalSpareParts.map((sparePartQuotationLine, index) => {
              return (
                <SparePartQuotationPendingCustomerValidationLine
                  key={sparePartQuotationLine.id}
                  onSelectSparePartQuotationLineChange={
                    onSelectSparePartQuotationLineConditionChange
                  }
                  sparePartQuotationLine={sparePartQuotationLine}
                  index={index}
                  preDataTestId="optionalSpareParts"
                />
              );
            })}
          </>
        )}
      </div>
    </>
  );
};

QuotationPendingCustomerValidationContent.displayName =
  'QuotationPendingCustomerValidationContent';

export default QuotationPendingCustomerValidationContent;
