import head from 'lodash/head';
import { createSelector } from 'reselect';

import { Claim, FullClaim } from '@savgroup-front-common/types';

import { CommonAppState } from '../CommonAppState';
import { Selectors as requestSelectors } from '../sagaRequestMetadata';
import { wasActionLoadedSelector } from '../sagaRequestMetadata/selectors';

import * as actionTypes from './actionTypes';
import {
  LOAD_FULL_CLAIMS_BY_GROUP_ID,
  LOAD_ISSUES_OPTIONS_BY_ORDER,
  LOAD_TRANSLATION,
} from './actionTypes';

export { selectStoreActorOptions } from './claimsSelectors/storeActor';

const claimsState = (state: CommonAppState) => state.claims;

export const claims = createSelector([claimsState], (state) =>
  state.get('claims'),
);

export const getClaimById = createSelector(
  [
    claimsState,
    (_: CommonAppState, { claimId }: { claimId?: string }) => claimId,
  ],
  (state, claimId): Claim | undefined => {
    return claimId
      ? state.getIn(['claims', claimId, 'value'], undefined)
      : undefined;
  },
);

interface FirstClaimToJS {
  value: Claim;
}

export const selectFirstClaim = createSelector([claimsState], (state) => {
  const claims = Object.values(state.get('claims').toJS());
  const firstClaim = head(claims) as FirstClaimToJS;

  return firstClaim?.value;
});

export const getClaimsByIds = createSelector(
  [
    claimsState,
    (_: CommonAppState, { claimIds }: { claimIds: string[] }) => claimIds,
  ],
  (state, claimIds = []) => {
    return {
      value: claimIds.reduce((acc, claimId) => {
        const value = state.getIn(['claims', claimId, 'value'], null);

        return {
          ...acc,
          [claimId]: value,
        };
      }, {}),
    };
  },
);

export const claimGroups = createSelector([claimsState], (state) =>
  state.get('claimGroups'),
);
export const claimGroupsById = createSelector(
  [
    claimGroups,
    (_: CommonAppState, { claimGroupId }: { claimGroupId: string }) =>
      claimGroupId,
  ],
  (state, claimGroupId) => state.getIn([claimGroupId, 'value'], []),
);
export const claimGroupStates = createSelector([claimsState], (state) =>
  state.get('claimGroupStates'),
);

export const claimGroupStatesWasLoaded = (state: CommonAppState): boolean =>
  requestSelectors.wasActionLoadedSelector(
    state,
    actionTypes.SELECT_HANDLING_FOR_CLAIM_GROUP,
  );

export const issuesForOrder = createSelector([claimsState], (state) =>
  state.get('issuesForOrder'),
);
export const issues = createSelector([claimsState], (state) =>
  state.get('issues'),
);
export const reasons = createSelector([claimsState], (state) =>
  state.get('reasons'),
);
export const solutions = createSelector([claimsState], (state) =>
  state.get('solutions'),
);
export const handling = createSelector([claimsState], (state) =>
  state.get('handling'),
);
export const carrier = createSelector([claimsState], (state) =>
  state.get('carrier'),
);
export const carriers = createSelector([claimsState], (state) =>
  state.get('carriers'),
);
export const pickupPoints = createSelector([claimsState], (state) =>
  state.get('pickupPoints'),
);
export const createdClaims = createSelector([claimsState], (state) =>
  state.get('createdClaims'),
);
export const createdClaimGroups = createSelector([claimsState], (state) =>
  state.get('createdClaimGroups'),
);
export const createdClaimsForOwner = createSelector([claimsState], (state) =>
  state.get('createdClaimsForOwner'),
);
export const createdClaimGroupsForOwner = createSelector(
  [claimsState],
  (state) => state.get('createdClaimGroupsForOwner'),
);
export const claimsConfirmationSelector = createSelector(
  [claimsState],
  (state) => state.get('claimsConfirmation'),
);

export const claimsConfirmationByClaimIdsSelector = createSelector(
  [
    claimsConfirmationSelector,
    (_: CommonAppState, { claimIds }: { claimIds: string[] }) => claimIds,
  ],
  (state, claimIds = []) => {
    return claimIds
      .map((claimId) => state.getIn([claimId, 'value']))
      .filter((v) => v);
  },
);

export const claimUploads = createSelector([claimsState], (state) =>
  state.get('claimUploads'),
);
export const claimUploadsValue = createSelector(
  [
    claimUploads,
    (_: CommonAppState, { claimId }: { claimId: string }) => claimId,
  ],
  (state, claimId) => {
    return state.get(claimId) || null;
  },
);

interface FullClaimToJS {
  value: FullClaim[];
}

export const selectFullClaims = createSelector([claimsState], (state) => {
  const fullClaims = state.get('fullClaims').toJS() as FullClaimToJS;

  return Object.entries(fullClaims).map(([claimId, fullClaim]) => ({
    ...fullClaim.value,
    claimId,
  }));
});
export const fullClaims = createSelector([claimsState], (state) =>
  state.get('fullClaims'),
);
export const getFullClaimById = createSelector(
  [
    fullClaims,
    (_: CommonAppState, { claimId }: { claimId: string }) => claimId,
  ],
  (state, claimId) => {
    return state.getIn([claimId, 'value'], {});
  },
);
export const selectFullClaimsByIds = createSelector(
  [
    fullClaims,
    (_: CommonAppState, { claimIds }: { claimIds: string[] }) => claimIds,
  ],
  (fullClaimsState, claimIds): FullClaim[] => {
    return claimIds.map((claimId) =>
      fullClaimsState.getIn([claimId, 'value'], []),
    );
  },
);

export const isBypassingDateRules = createSelector([claimsState], (state) =>
  state.get('isBypassingDateRules'),
);
export const products = createSelector([claimsState], (state) =>
  state.get('products'),
);
export const comments = createSelector([claimsState], (state) =>
  state.get('comments'),
);

export const groupHandling = createSelector([claimsState], (state) =>
  state.get('groupHandling'),
);
export const groupCarrier = createSelector([claimsState], (state) =>
  state.get('groupCarrier'),
);
export const groupCarriers = createSelector([claimsState], (state) =>
  state.get('groupCarriers'),
);
export const claimGroupsConfirmation = createSelector([claimsState], (state) =>
  state.get('claimGroupsConfirmation'),
);
export const getClaimGroupsConfirmationValue = createSelector(
  [claimsState, (_: CommonAppState, claimGroupId: string) => claimGroupId],
  (state, claimGroupId) => {
    return {
      isLoaded: state.getIn(
        ['claimGroupsConfirmation', claimGroupId, 'isLoaded'],
        null,
      ),
      hasErrors: state.getIn(
        ['claimGroupsConfirmation', claimGroupId, 'hasErrors'],
        null,
      ),
      value: state.getIn(
        ['claimGroupsConfirmation', claimGroupId, 'value'],
        null,
      ),
    };
  },
);

export const claimConfirmationStatus = createSelector([claimsState], (state) =>
  state.get('claimConfirmationStatus'),
);
export const getClaimConfirmationStatus = createSelector(
  [claimsState, (_: CommonAppState, claimGroupId: string) => claimGroupId],
  (state, claimGroupId) => {
    return {
      isLoaded: state.getIn(
        ['claimConfirmationStatus', claimGroupId, 'isLoaded'],
        null,
      ),
      hasErrors: state.getIn(
        ['claimConfirmationStatus', claimGroupId, 'hasErrors'],
        null,
      ),
      value: state.getIn(
        ['claimConfirmationStatus', claimGroupId, 'value'],
        null,
      ),
    };
  },
);

export const sellerInfo = createSelector([claimsState], (state) =>
  state.get('sellerInfo'),
);

export const orderIssues = createSelector([claimsState], (state) =>
  state.get('orderIssues'),
);

export const claimInfoDebugger = createSelector([claimsState], (state) =>
  state.get('claimInfoDebugger'),
);

export const rmaSupplierInfos = createSelector([claimsState], (state) =>
  state.get('rmaSupplierInfos'),
);
export const rmaSupplierInfosValue = createSelector(
  [claimsState, (_: CommonAppState, claimId: string | undefined) => claimId],
  (state, claimId) => {
    return {
      hasErrors: state.getIn(['rmaSupplierInfos', claimId, 'hasErrors'], null),
      isLoaded: state.getIn(['rmaSupplierInfos', claimId, 'isLoaded'], null),
      value: state.getIn(['rmaSupplierInfos', claimId, 'value'], null),
    };
  },
);

export const procedureMarkdown = createSelector([claimsState], (state) =>
  state.get('procedureMarkdown'),
);

export const orderIdByClaimGroup = createSelector(
  [claimsState, (_: CommonAppState, claimGroupId: string) => claimGroupId],
  (state, claimGroupId) => {
    return {
      hasErrors: state.getIn(
        ['orderIdByClaimGroup', claimGroupId, 'hasErrors'],
        null,
      ),
      isLoaded: state.getIn(
        ['orderIdByClaimGroup', claimGroupId, 'isLoaded'],
        null,
      ),
      value: state.getIn(['orderIdByClaimGroup', claimGroupId, 'value'], null),
    };
  },
);

export const documentForClaimGroup = createSelector(
  [claimsState, (_: CommonAppState, claimGroupId: string) => claimGroupId],
  (state, claimGroupId) => {
    return {
      isLoaded: state.getIn(
        ['claimGroupUpload', claimGroupId, 'isLoaded'],
        null,
      ),
      hasErrors: state.getIn(
        ['claimGroupUpload', claimGroupId, 'hasErrors'],
        null,
      ),
      value: state.getIn(['claimGroupUpload', claimGroupId, 'value'], null),
    };
  },
);

export const translationsSelector = createSelector([claimsState], (state) =>
  state.get('translations'),
);

export const translationsIssuesSelector = createSelector(
  [translationsSelector],
  (state): Record<string, string> =>
    state.get('issues') as unknown as Record<string, string>,
);
export const translationsReasonsSelector = createSelector(
  [translationsSelector],
  (state): Record<string, string> =>
    state.get('reasons') as unknown as Record<string, string>,
);
export const translationsWarrantiesSelector = createSelector(
  [translationsSelector],
  (state): Record<string, string> =>
    state.get('warranties') as unknown as Record<string, string>,
);
export const translationsSolutionsSelector = createSelector(
  [translationsSelector],
  (state) => state.get('solutions'),
);

export const translationIsLoaded = (
  state: CommonAppState,
  { ownerProductId }: { ownerProductId: string },
): boolean | undefined =>
  requestSelectors.wasActionLoadedSelector(
    state,
    actionTypes.LOAD_TRANSLATION,
    ownerProductId,
  );

export const selectIsDeleteClaimGroupLoading = (
  state: CommonAppState,
  { claimGroupId }: { claimGroupId: string },
): boolean | undefined =>
  requestSelectors.isActionLoadingSelector(
    state,
    actionTypes.DELETE_CLAIM_GROUP_BY_ID,
    claimGroupId,
  );

export const selectGetFullClaimsByGroupIdWasLoaded = (
  state: CommonAppState,
  { claimGroupId }: { claimGroupId: string },
): boolean | undefined => {
  return requestSelectors.wasActionLoadedSelector(
    state,
    LOAD_FULL_CLAIMS_BY_GROUP_ID,
    claimGroupId,
  );
};

export const selectLoadIssuesOptionsByOrderWasLoaded = (
  state: CommonAppState,
  { indexer }: { indexer: string },
) => wasActionLoadedSelector(state, LOAD_ISSUES_OPTIONS_BY_ORDER, indexer);

export const selectLoadClaimByIdWasLoaded = (
  state: CommonAppState,
  { claimGroupId }: { claimGroupId: string },
): boolean | undefined => {
  return requestSelectors.wasActionLoadedSelector(
    state,
    actionTypes.LOAD_CLAIM_GROUP_BY_ID,
    claimGroupId,
  );
};

export const selectGetTranslationWasLoaded = (
  state: CommonAppState,
  { indexer }: { indexer: string },
): boolean => wasActionLoadedSelector(state, LOAD_TRANSLATION, indexer);
