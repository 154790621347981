import result from 'lodash/result';

export const MARKETPLACES = {
  Amazon: 'amazon',
  BackMarket: 'backmarket',
  CDiscount: 'cdiscount',
  Fnac: 'fnac',
  PriceMinister: 'priceminister',
  Rakuten: 'rakuten',
  Truffaut: 'truffaut',
  FnacDarty: 'fnac darty',
  DecathlonSeller: 'decathlon seller',
  BoulangerSeller: 'boulanger seller',
};

export const SALES_CHANNEL = {
  ...MARKETPLACES,
  AmerSports: 'amersports',
  Carrefour: 'carrefour',
  RueDuCommerce: 'rdc',
};

export const getFormattedSalesChannelName = (name) => {
  switch (result(name, 'toLowerCase')) {
    case MARKETPLACES.Amazon:
      return 'Amazon';
    case MARKETPLACES.BackMarket:
      return 'BackMarket';
    case MARKETPLACES.CDiscount:
      return 'CDiscount';
    case MARKETPLACES.Fnac:
      return 'Fnac';
    case MARKETPLACES.Truffaut:
      return 'Truffaut';
    case MARKETPLACES.PriceMinister:
    case MARKETPLACES.Rakuten:
      return 'Rakuten';
    default:
      return name;
  }
};

export const getSalesChannelLogo = (name) => {
  const base = `${process.env.REACT_APP_STATIC_HASH}/images/marketplaces/`;

  switch (result(name, 'toLowerCase')) {
    case MARKETPLACES.Amazon:
      return `${base}Amazon.svg`;
    case SALES_CHANNEL.AmerSports:
      return `${base}Amersports.svg`;
    case MARKETPLACES.BackMarket:
      return `${base}BackMarket.svg`;
    case SALES_CHANNEL.Carrefour:
      return `${base}Carrefour.svg`;
    case MARKETPLACES.CDiscount:
      return `${base}CDiscount.png`;
    case MARKETPLACES.Fnac:
      return `${base}Fnac.svg`;
    case MARKETPLACES.FnacDarty:
      return `${base}FnacDarty.svg`;
    case MARKETPLACES.PriceMinister:
    case MARKETPLACES.Rakuten:
      return `${base}Rakuten.svg`;
    case SALES_CHANNEL.RueDuCommerce:
      return `${base}RueDuCommerce.svg`;
    case SALES_CHANNEL.BoulangerSeller:
      return `${base}Boulanger.svg`;
    case SALES_CHANNEL.DecathlonSeller:
      return `${base}Decathlon.svg`;
    default:
      return undefined;
  }
};
