import map from 'lodash/map';
import PropTypes from 'prop-types';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Banner } from '@savgroup-front-common/core/src/atoms/Banners';
import { Card } from '@savgroup-front-common/core/src/atoms/Cards';
import {
  Autocomplete,
  FormGroup,
} from '@savgroup-front-common/core/src/atoms/Form';
import { Label } from '@savgroup-front-common/core/src/atoms/Form/common/Label/Label';
import { AdditionalInformation } from '@savgroup-front-common/core/src/components/AdditionalInformationWithReactHookForm';
import { MDXContent } from '@savgroup-front-common/core/src/components/MDXContent';
import { SafeFormattedMessage } from '@savgroup-front-common/core/src/formatters';
import { TextareaHookForm } from '@savgroup-front-common/core/src/molecules/Form';

import useReasonSolutionSelectionStoreActions from '../hooks/useReasonSolutionSelectionStoreActions';

import { CustomComponents } from './CustomComponents/CustomComponents';
import messages from './messages';
import ReasonFileUpload from './ReasonFileUpload';
import { REASON_SELECTION_FORM_FIELDS } from './ReasonSelectionForm.constants';
import {
  $AdditionalInformationContainer,
  $UploadLegend,
} from './ReasonSelectionForm.styles';

const ReasonSelectionForm = ({
  isOpen,
  reasons,
  reasonsLoaded,
  claimIds,
  product,
  onAdditionalInformationSave,
}) => {
  const {
    dispatchSetReasonCommentForClaimGroup,
    dispatchSelectQuantityReason,
  } = useReasonSolutionSelectionStoreActions();

  const options = map(reasons, (reason) => ({
    value: reason.id,
    label: reason.name,
    data: reason,
  }));

  const formContext = useFormContext();
  const {
    control,
    register,
    formState: { errors },
    getValues,
  } = formContext;
  const reason = getValues(`form.${product.productId}.reason`)?.data;
  const reasonComment = getValues(`form.${product.productId}.reasonComment`);

  const isDocumentMandatory = reason?.mandatoryDocument;

  const neededInformation = reason?.neededInformation || [];
  const isCommentMandatory = reason?.mandatoryComment;
  const didactic = reason?.didactic;

  return (
    <Card isOpen={isOpen}>
      <Controller
        control={control}
        name={`form.${product?.productId}.reason`}
        render={({ field }) => (
          <Autocomplete
            name={field.name}
            label={messages.reason}
            options={options}
            onChange={(option) => {
              dispatchSelectQuantityReason({
                claimIds,
                reasonId: option?.value,
                reasonComment,
              });

              field.onChange(option);
            }}
            ref={field.ref}
            value={field.value}
            placeholder={messages.specifyReason}
            isLoading={!reasonsLoaded}
            dataTestIdForCypress="data-reason-dropdown"
            errors={errors}
            isRequired
          />
        )}
      />
      <$AdditionalInformationContainer>
        {reason && (
          <AdditionalInformation
            onAdditionalInformationSave={onAdditionalInformationSave}
            neededInformation={neededInformation}
            prefixFormName={`${REASON_SELECTION_FORM_FIELDS.ADDITIONAL_INFORMATION}.${product.sellerProductId}`}
            isLiveUpload
            shouldAllowQrCode={false}
          />
        )}
      </$AdditionalInformationContainer>

      <FormGroup>
        <TextareaHookForm
          name={`form.${product?.productId}.reasonComment`}
          autoHeight={false}
          isRequired={isCommentMandatory}
          placeholder={messages.commentPlaceholder}
          label={messages.comment}
          onBlur={() =>
            dispatchSetReasonCommentForClaimGroup({
              claimGroupId: product.claimGroupId,
              reasonComment,
              ownerProductIds: product.productIds,
            })
          }
          errors={errors}
          data-comment-textarea
          register={register}
        />
      </FormGroup>

      {didactic && (
        <FormGroup>
          <Banner isFluid>
            <MDXContent customComponents={CustomComponents}>
              {didactic}
            </MDXContent>
          </Banner>
        </FormGroup>
      )}
      {reason && (
        <>
          {isDocumentMandatory && (
            <>
              <Label htmlFor="file" required>
                <SafeFormattedMessage {...messages.document} />
              </Label>
              <$UploadLegend>
                <SafeFormattedMessage {...messages.miscUploadLabel} />
              </$UploadLegend>
            </>
          )}
          <div style={{ marginTop: '8px' }}>
            <ReasonFileUpload
              name={`form.${product?.productId}.reasonDocument`}
              activeProduct={product}
            />
          </div>
        </>
      )}
    </Card>
  );
};

ReasonSelectionForm.propTypes = {
  reason: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  reasons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  reasonsLoaded: PropTypes.bool,
  reasonComment: PropTypes.string,
  product: PropTypes.shape({}).isRequired,
  onSelectClaimReason: PropTypes.func,
  onChangeComment: PropTypes.func,
  onSaveComment: PropTypes.func,
  isCommentMandatoryError: PropTypes.bool,
  isDocumentMandatoryError: PropTypes.bool,
  claimIds: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
};

ReasonSelectionForm.defaultProps = {
  reason: null,
  reasons: [],
  reasonsLoaded: false,
  reasonComment: null,
  onSelectClaimReason: () => {},
  onChangeComment: () => {},
  onSaveComment: () => {},
  isCommentMandatoryError: false,
  isDocumentMandatoryError: false,
};

ReasonSelectionForm.displayName = 'ReasonSelectionForm';

export default ReasonSelectionForm;
