import React, { FunctionComponent, ReactElement } from 'react';
import { MessageDescriptor } from 'react-intl';

import { ButtonProps } from '@savgroup-front-common/core/src/atoms/button';
import {
  SafeFormattedMessage,
  SafeFormattedMessageWithoutSpread,
} from '@savgroup-front-common/core/src/formatters';
import {
  MessageType,
  SUPPORTED_COMPONENTS,
} from '@savgroup-front-common/types';

import { useLayout } from '../NewLayout.hooks';

import Banner from './Banner';
import {
  $ActionButton,
  $Breadcrumb,
  $BreadcrumbWrapper,
  $Description,
  $HeadingAction,
  $HeadingTitle,
  $HeadingWrapper,
  $Title,
} from './BanneredLayout.styles';

const BannerTitle: FunctionComponent<{
  breadcrumbs?: {
    label: string | MessageType | MessageDescriptor;
    route?: string;
  }[];
  title?: string | MessageType | MessageDescriptor;
  description?: string | MessageType | MessageDescriptor;
  actions?: {
    label: string | MessageType | MessageDescriptor;
    route: string;
    icon?: ReactElement;
    props?: ButtonProps;
    color?: string;
    backgroundColor?: string;
  }[];
}> = ({ breadcrumbs, title, description, actions }) => {
  const { enableServicePortal } = useLayout();

  return (
    <Banner>
      <$BreadcrumbWrapper>
        {breadcrumbs?.map((breadcrumb, index) => (
          <$Breadcrumb
            key={breadcrumb.label.toString()}
            active={breadcrumbs.length - 1 === index}
            naked
            href={breadcrumb.route}
            isExternal={false}
            data-gohomepage-button
            componentThemeName={SUPPORTED_COMPONENTS.BACK_BUTTON}
          >
            <SafeFormattedMessageWithoutSpread message={breadcrumb.label} />
          </$Breadcrumb>
        ))}
      </$BreadcrumbWrapper>
      <$HeadingWrapper>
        <$HeadingTitle>
          {title && (
            <$Title $hasBannerUrl={enableServicePortal}>
              <SafeFormattedMessageWithoutSpread message={title} />
            </$Title>
          )}
          {description && (
            <$Description $hasBannerUrl={enableServicePortal}>
              <SafeFormattedMessageWithoutSpread message={description} />
            </$Description>
          )}
        </$HeadingTitle>
        {actions && (
          <$HeadingAction>
            {actions?.map((action) => (
              <$ActionButton
                key={action.label.toString()}
                color={action.color}
                backgroundColor={action.backgroundColor}
                icon={action.icon}
                href={action.route}
              >
                <span>{SafeFormattedMessage(action.label)}</span>
              </$ActionButton>
            ))}
          </$HeadingAction>
        )}
      </$HeadingWrapper>
    </Banner>
  );
};

BannerTitle.displayName = 'BannerTitle';
export default BannerTitle;
