import { APIConfiguration } from '@savgroup-front-common/configuration';
import { urlFormatter } from '@savgroup-front-common/core/src/formatters';
import { getInitialLocale } from '@savgroup-front-common/core/src/helpers';
import {
  UserManagerConfig,
  UserManagerDataCache,
  userManagerHelper,
} from '@savgroup-front-common/core/src/services';
import { AuthConfiguration } from 'myaccount/configuration';

const CONCERNED_DOMAIN: Record<string, Record<string, string>> = {
  PROD: {
    AUCHAN: 'sav.auchan.fr',
    BOULANGER: 'boulanger.revers.io',
    BHV: 'bhv.revers.io',
    MDM: 'mesretours.maisonsdumonde.com',
  },
  PREPROD: { BOULANGER: 'preprod-boulanger.revers.io' },
  DEMO: {
    DEFAULT: 'demo-myaccount.revers.io',
    CARREFOUR: 'demo-carrefour.revers.io',
    TRUFFAUT: 'demo-truffaut.revers.io',
    BHV: 'demo-bhv.revers.io',
    BRICO: 'demo-brico.revers.io',
    ATELIER_AMAYA: 'demo-atelieramaya.revers.io',
    AUCHAN: 'demo-auchan.revers.io',
    DEMO_SALES: 'demosales.revers.io',
    MMOUSTACHE: 'demo-mmoustache.revers.io',
    WITHINGS: 'demo-withings.revers.io',
    LIGHT_ONLINE: 'demo-lightonline.revers.io',
    LECLERC: 'demo-leclerc.revers.io',
    LIGHTONLINE: 'demo-lightonline.revers.io',
    JARDILAND: 'demo-jardiland.revers.io',
    GUCCI: 'demo-gucci.revers.io',
    BOULANGER: 'demo-boulanger.revers.io',
    CONFORAMA: 'demo-conforama.revers.io',
    ALPHA_TEST: 'demo-alpha.revers.io',
    MDM: 'mesretours.mdm-staging.com',
    REVERSIO: 'demo-reversio.revers.io',
    SOBRICO: 'demo-sobrico.revers.io',
    DINO: 'demo-dino.revers.io',
  },
  DEFAULT: {
    DEFAULT: 'dev-myaccount.revers.io',
    CARREFOUR: 'dev-carrefour.revers.io',
    TRUFFAUT: 'dev-truffaut.revers.io',
    BHV: 'dev-bhv.revers.io',
    BRICO: 'dev-brico.revers.io',
    TEST_PRACTICE: 'dev-project.revers.io',
    AUCHAN: 'sav.dev.auchan.fr',
    TEST_SELLER: 'dev-seller.revers.io',
    ALPHA_TEST: 'dev-alpha.revers.io',
    BOULANGER: 'dev-boulanger.revers.io',
    OCP: 'dev-ocp.revers.io',
    REVERSIO: 'reversio.dev.revers.io',
  },
  DEV: {
    DEFAULT: 'dev-myaccount.revers.io',
    CARREFOUR: 'dev-carrefour.revers.io',
    TRUFFAUT: 'dev-truffaut.revers.io',
    BHV: 'dev-bhv.revers.io',
    BRICO: 'dev-brico.revers.io',
    TEST_PRACTICE: 'dev-project.revers.io',
    AUCHAN: 'sav.dev.auchan.fr',
    TEST_SELLER: 'dev-seller.revers.io',
    ALPHA_TEST: 'dev-alpha.revers.io',
    BOULANGER: 'dev-boulanger.revers.io',
    OCP: 'dev-ocp.revers.io',
    REVERSIO: 'reversio.dev.revers.io',
    DINO: 'dev-dino.revers.io',
  },
  LOCAL: {
    DEFAULT: 'dev-myaccount.revers.io',
    CARREFOUR: 'dev-carrefour.revers.io',
    TRUFFAUT: 'dev-truffaut.revers.io',
    BHV: 'dev-bhv.revers.io',
    BRICO: 'dev-brico.revers.io',
    TEST_PRACTICE: 'dev-project.revers.io',
    AUCHAN: 'dev-auchan.revers.io',
    TEST_SELLER: 'dev-seller.revers.io',
  },
};

async function fetchAuthorityUrl() {
  try {
    const { hostname } = window.location;

    if (hostname === 'localhost') {
      const env = process.env.REACT_APP_ENV || 'DEFAULT';
      const neededDomain = (process.env.REACT_APP_NEEDED_DOMAIN ||
        'DEFAULT') as string;
      const domain = CONCERNED_DOMAIN[env][neededDomain];

      const response = await fetch(
        `${APIConfiguration.config}sellers/idsUrl/${domain}`,
      );

      const { value: authority } = await response.json();

      const builtAuthorityUrl =
        urlFormatter.addHttpsProtocolIfDoesNotExist(authority);

      AuthConfiguration.setAuthority(builtAuthorityUrl);
    } else {
      const response = await fetch(
        `${APIConfiguration.config}sellers/idsUrl/${hostname}`,
      );
      const { value } = await response.json();
      const builtAuthorityUrl =
        urlFormatter.addHttpsProtocolIfDoesNotExist(value);

      AuthConfiguration.setAuthority(builtAuthorityUrl);
    }
  } catch (e) {
    if (AuthConfiguration.clientId.includes('.revers.io')) {
      const defaultValue = `https://${AuthConfiguration.clientId.replace(
        '.revers.io',
        '-login.revers.io',
      )}`;

      AuthConfiguration.setAuthority(defaultValue);
    } else {
      AuthConfiguration.setAuthority();
    }
  }
}

export class ControlUserManagerConfig extends UserManagerConfig {
  public static async getCacheAsync(): Promise<UserManagerDataCache> {
    const userManagerConfigCache = this._cache;

    if (userManagerConfigCache?.userManagerConfig) {
      return userManagerConfigCache;
    }
    await fetchAuthorityUrl();

    const userManagerHelperOutput = userManagerHelper(
      AuthConfiguration,
      getInitialLocale as any,
    );

    UserManagerConfig.cache = userManagerHelperOutput;

    return userManagerHelperOutput;
  }
}
