import React, { FC, useState } from 'react';
import { FormProvider } from 'react-hook-form';

import {
  BUTTON_TYPES,
  PERMISSIONS,
  TARGET_LINK,
} from '@savgroup-front-common/constants';
import {
  CUSTOMER_TYPE,
  HolderType,
  QUOTATION_PAYMENT_TYPE,
  QuotationState,
} from '@savgroup-front-common/types';

import Bold from '../../../atoms/Bold/Bold';
import { Button } from '../../../atoms/button';
import { Link } from '../../../atoms/link';
import { QualireparLogo } from '../../../atoms/Logo/QualireparLogo/QualireparLogo';
import { MODAL_SIZES } from '../../../atoms/modal';
import { Tag } from '../../../atoms/Tag';
import {
  formatQuotationState,
  SafeFormattedMessageWithoutSpread,
} from '../../../formatters';
import { useHasPermission } from '../../../hooks';
import useGetSellerConfigurationById from '../../../hooks/useGetSellerConfigurationById';
import { ACCORDION_ROLE } from '../../../molecules/Accordion/Accordion.types';
import AccordionWithLabel from '../../../molecules/Accordion/AccordionWithLabel/AccordionWithLabel';
import { ConfirmModal } from '../../../molecules/ConfirmModal';
import { AdaptedQuotationNewModel } from '../types/AdaptedQuotationNewModel';

import { ConfirmQuotationRejectModal } from './ConfirmQuotationRejectModalContent';
import ConfirmQuotationValidationBusinessCustomerType from './ConfirmQuotationValidationBusinessCustomerType/ConfirmQuotationValidationBusinessCustomerType';
import messages from './messages';
import { QuotationPendingCustomerValidationContextProvider } from './QuotationPendingCustomerValidation.context';
import { useQuotationPendingCustomerValidation } from './QuotationPendingCustomerValidation.hooks';
import {
  $ActionRow,
  $BonusLine,
  $Description,
} from './QuotationPendingCustomerValidation.styles';
import { QuotationPendingCustomerValidationContent } from './QuotationPendingCustomerValidationContent';
import { QuotationTitle } from './QuotationTitle';
import { QuotationTotal } from './QuotationTotal';

interface QuotationPendingCustomerValidationProps {
  customerType?: CUSTOMER_TYPE;
  quotation: AdaptedQuotationNewModel;
  initialIsOpen?: boolean;
  isOpen?: boolean;
  sellerId: string;
  fileId: string;
  onAnswerSuccess: () => void;
  holderType: HolderType;
}

const QuotationPendingCustomerValidation: FC<
  QuotationPendingCustomerValidationProps
> = ({
  customerType = CUSTOMER_TYPE.INDIVIDUAL,
  quotation,
  initialIsOpen = false,
  isOpen,
  sellerId,
  fileId,
  onAnswerSuccess,
  holderType,
}) => {
  const {
    formContext,
    adaptedQuotation,
    handleValidate,
    isValidateLoading,
    optionalSpareParts,
    handleCancelQuotation,
    isCancelQuotationLoading,
    isConfirmRejectModalOpen,
    isRejectLoading,
    handleRejectButtonClick,
    handleRejectWithReturn,
    handleRejectWithDestruction,
    handleCancelConfirmReject,
    isSelectSparePartConditionLoading,
    handleSelectSparePartQuotationLineConditionChange,
    requiredForRepaidSpareParts,
    isOpenBusinessPaymentTypeModal,
    handleOpenConfirmQuotationValidationBusiness,
  } = useQuotationPendingCustomerValidation({
    quotation,
    sellerId,
    onAnswerSuccess,
    fileId,
    holderType,
  });
  const { formState, handleSubmit, watch } = formContext;
  const paymentTypeFormWatched = watch('paymentType');

  const { data: sellerConfiguration } = useGetSellerConfigurationById({
    sellerId,
  });

  const [
    isCancelQuotationConfirmModalOpen,
    setIsCancelQuotationConfirmModalOpen,
  ] = useState(false);
  const [
    isValidateQuotationConfirmModalOpen,
    setIsValidateQuotationConfirmModalOpen,
  ] = useState(false);

  const hasInteractWithQuotationTabPermission = useHasPermission(
    PERMISSIONS.INTERACT_WITH_QUOTATION_TAB,
  );

  const hasBusinessCustomerType = Boolean(
    customerType !== CUSTOMER_TYPE.NOT_SET &&
      customerType !== CUSTOMER_TYPE.INDIVIDUAL,
  );

  return (
    <QuotationPendingCustomerValidationContextProvider
      quotation={adaptedQuotation}
    >
      <FormProvider {...formContext}>
        <AccordionWithLabel
          title={
            <QuotationTitle quotation={adaptedQuotation} fileId={fileId} />
          }
          label={
            <Tag alert dataTestId="quotation_status">
              <SafeFormattedMessageWithoutSpread
                message={formatQuotationState(
                  QuotationState.PENDING_CUSTOMER_APPROVAL,
                )}
              />
            </Tag>
          }
          isOpen={isOpen}
          initialIsOpen={initialIsOpen}
          role={ACCORDION_ROLE.PRIMARY}
        >
          <form onSubmit={handleSubmit(() => handleValidate())}>
            <$Description data-testid="QuotationPendingCustomerValidation_description">
              <strong>
                <SafeFormattedMessageWithoutSpread
                  message={messages.descriptionLabel}
                />{' '}
                :{' '}
              </strong>
              {adaptedQuotation.description}
            </$Description>

            <QuotationPendingCustomerValidationContent
              adaptedQuotation={adaptedQuotation}
              requiredForRepaidSpareParts={requiredForRepaidSpareParts}
              optionalSpareParts={optionalSpareParts}
              onSelectSparePartQuotationLineConditionChange={
                handleSelectSparePartQuotationLineConditionChange
              }
            />
            <QuotationTotal fileId={fileId} quotation={adaptedQuotation} />

            {adaptedQuotation.bonus && !adaptedQuotation.isBonusRemoved && (
              <$BonusLine data-testid="bonus">
                <QualireparLogo />
                <div>
                  <Bold>
                    <SafeFormattedMessageWithoutSpread
                      message={messages.congratulation}
                    />
                  </Bold>
                  <p>
                    <SafeFormattedMessageWithoutSpread
                      message={messages.bonusDidactic}
                    />
                    {sellerConfiguration?.qualiReparUrl && (
                      <Link
                        isExternal
                        href={sellerConfiguration?.qualiReparUrl}
                        target={TARGET_LINK.TARGET_BLANK}
                      >
                        <SafeFormattedMessageWithoutSpread
                          message={messages.learnMore}
                        />
                      </Link>
                    )}
                  </p>
                </div>
              </$BonusLine>
            )}

            {hasInteractWithQuotationTabPermission && (
              <$ActionRow>
                <Button
                  type={BUTTON_TYPES.BUTTON}
                  tertiary
                  isLoading={
                    isSelectSparePartConditionLoading ||
                    isCancelQuotationLoading
                  }
                  onClick={() => setIsCancelQuotationConfirmModalOpen(true)}
                >
                  <SafeFormattedMessageWithoutSpread
                    message={messages.buttonCancel}
                  />
                </Button>
                <Button
                  type={BUTTON_TYPES.BUTTON}
                  danger
                  onClick={() => handleRejectButtonClick()}
                  isLoading={
                    isSelectSparePartConditionLoading || isRejectLoading
                  }
                >
                  <SafeFormattedMessageWithoutSpread
                    message={messages.buttonReject}
                  />
                </Button>
                {hasBusinessCustomerType ? (
                  <Button
                    type={BUTTON_TYPES.BUTTON}
                    success
                    onClick={() =>
                      handleOpenConfirmQuotationValidationBusiness()
                    }
                    isLoading={
                      isSelectSparePartConditionLoading || isValidateLoading
                    }
                    disabled={!formState.isValid}
                  >
                    <SafeFormattedMessageWithoutSpread
                      message={messages.buttonValidate}
                    />
                  </Button>
                ) : (
                  <Button
                    type={BUTTON_TYPES.SUBMIT}
                    success
                    isLoading={
                      isSelectSparePartConditionLoading || isValidateLoading
                    }
                    disabled={!formState.isValid}
                  >
                    <SafeFormattedMessageWithoutSpread
                      message={messages.buttonValidate}
                    />
                  </Button>
                )}
              </$ActionRow>
            )}

            <ConfirmModal
              isOpen={isCancelQuotationConfirmModalOpen}
              size={MODAL_SIZES.SMALL}
              title={messages.confirmActionTitle}
              didactic={messages.thisActionIsNoReversible}
              cancelMessage={messages.cancel}
              confirmMessage={messages.confirmActionButton}
              onCancel={() => setIsCancelQuotationConfirmModalOpen(false)}
              onConfirm={handleCancelQuotation}
              isLoading={
                isSelectSparePartConditionLoading || isCancelQuotationLoading
              }
            />

            <ConfirmModal
              isOpen={isValidateQuotationConfirmModalOpen}
              size={MODAL_SIZES.SMALL}
              title={messages.confirmActionTitle}
              didactic={messages.thisActionIsNoReversible}
              cancelMessage={messages.cancel}
              confirmMessage={messages.confirmActionButton}
              onCancel={() => setIsValidateQuotationConfirmModalOpen(false)}
              onConfirm={handleSubmit(() =>
                handleValidate(QUOTATION_PAYMENT_TYPE.IMMEDIATE),
              )}
              isLoading={isSelectSparePartConditionLoading || isValidateLoading}
            />
            <ConfirmQuotationValidationBusinessCustomerType
              isOpen={isOpenBusinessPaymentTypeModal}
              title={messages.paymentTypeModalTitle}
              didactic={messages.paymentTypeModalDidactics}
              cancelMessage={messages.cancel}
              confirmMessage={messages.confirmActionButton}
              isLoading={isSelectSparePartConditionLoading || isValidateLoading}
              size={MODAL_SIZES.SMALL}
              onCancel={() => handleOpenConfirmQuotationValidationBusiness()}
              onConfirm={handleSubmit(() =>
                handleValidate(
                  paymentTypeFormWatched?.value as QUOTATION_PAYMENT_TYPE,
                ),
              )}
              formContext={formContext}
            />
          </form>
          <ConfirmQuotationRejectModal
            isConfirmRejectModalOpen={isConfirmRejectModalOpen}
            handleCancelConfirmReject={handleCancelConfirmReject}
            handleRejectWithDestruction={handleRejectWithDestruction}
            handleRejectWithReturn={handleRejectWithReturn}
            quotation={quotation}
          />
        </AccordionWithLabel>
      </FormProvider>
    </QuotationPendingCustomerValidationContextProvider>
  );
};

QuotationPendingCustomerValidation.displayName =
  'QuotationPendingCustomerValidation';

export default QuotationPendingCustomerValidation;
