import React, { FunctionComponent, RefObject } from 'react';
import { useTheme } from 'styled-components';

import { BUTTON_TYPES } from '@savgroup-front-common/constants';

import {
  SafeFormattedMessage,
  SafeFormattedMessageWithoutSpread,
} from '../../formatters';
import { useIsNewUiEnabled } from '../../hooks/useIsNewUiEnabled';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  LoaderIcon,
} from '../../protons/icons';

import {
  calculateEasing,
  VERTICAL_MENU_ANIMATION_DURATION,
} from './Menu.helpers';
import useMenu from './Menu.hooks';
import {
  $MenuBack,
  $MenuButton,
  $MenuContent,
  $MenuItemStyled,
  $MenuLink,
  $MenuSubMenu,
  $MenuTextContainer,
  $MenuTitle,
} from './Menu.styles';
import { MENU_ITEM_TYPES, MENU_POSITIONS, MenuItem } from './Menu.types';
import MenuAnimator from './MenuAnimator';
import messages from './messages';

interface VerticalMenuitem {
  menuItems: MenuItem[];
  onClose?: () => void;
  position?: MENU_POSITIONS;
  dataTestId?: string;
  wrapperRef: RefObject<any>;
  componentThemeName?: string;
  maxHeight?: string;
}
const VerticalMenuContent: FunctionComponent<
  React.PropsWithChildren<VerticalMenuitem>
> = ({
  menuItems = [],
  onClose = () => undefined,
  position,
  dataTestId,
  wrapperRef,
  componentThemeName,
  maxHeight,
}) => {
  const { disabledAnimation, classNames, list, path } = useMenu({
    onClose,
    menuItems,
    wrapperRef,
  });
  const theme = useTheme();
  const isNewUiEnabled = useIsNewUiEnabled();

  return (
    <MenuAnimator
      position={position}
      dataTestId={dataTestId}
      classNames={classNames}
      currentDepth={path.length}
      componentThemeName={componentThemeName}
      maxHeight={maxHeight}
    >
      {list.map((menuItem, index) => {
        let component;

        switch (menuItem.type) {
          case MENU_ITEM_TYPES.CUSTOM:
            component = menuItem.children;
            break;

          case MENU_ITEM_TYPES.TITLE:
            component = (
              <$MenuTitle
                data-testid={
                  dataTestId
                    ? `${dataTestId}_menu_child_${MENU_ITEM_TYPES.TITLE}`
                    : `menu_child_${MENU_ITEM_TYPES.TITLE}`
                }
                ref={menuItem.ref}
                $isNewUiEnabled={isNewUiEnabled}
              >
                <$MenuContent>
                  {menuItem.isLoading && (
                    <LoaderIcon
                      size="32px"
                      color={
                        isNewUiEnabled
                          ? theme.colors.mainTextColor
                          : theme.colors.white
                      }
                    />
                  )}
                  {SafeFormattedMessage(menuItem.label)}
                </$MenuContent>
              </$MenuTitle>
            );
            break;

          case MENU_ITEM_TYPES.LINK:
            component = (
              <$MenuLink
                href={menuItem.href}
                ref={menuItem.ref}
                data-testid={
                  dataTestId
                    ? `${dataTestId}_menu_child_${MENU_ITEM_TYPES.LINK}`
                    : `menu_child_${MENU_ITEM_TYPES.LINK}`
                }
                $color={menuItem.color}
                $isDisabled={menuItem.isDisabled}
                target={menuItem.target}
                $isNewUiEnabled={isNewUiEnabled}
              >
                <$MenuContent>
                  {menuItem.isLoading ? (
                    <LoaderIcon size="32px" color={theme.colors.white} />
                  ) : (
                    menuItem.icon
                  )}
                  {SafeFormattedMessage(menuItem.label)}
                </$MenuContent>
              </$MenuLink>
            );
            break;

          case MENU_ITEM_TYPES.MENU:
            component = (
              <$MenuSubMenu
                onClick={menuItem.onClick}
                type={BUTTON_TYPES.BUTTON}
                disabled={menuItem.isDisabled}
                data-testid={
                  dataTestId
                    ? `${dataTestId}_menu_child_${MENU_ITEM_TYPES.MENU}`
                    : `menu_child_${MENU_ITEM_TYPES.MENU}`
                }
                $color={menuItem.color}
                $isNewUiEnabled={isNewUiEnabled}
              >
                <$MenuContent>
                  {menuItem.isLoading ? (
                    <LoaderIcon size="32px" color={theme.colors.white} />
                  ) : (
                    menuItem.icon
                  )}
                  {SafeFormattedMessage(menuItem.label)}
                </$MenuContent>
                <ChevronRightIcon size="32px" color={theme.colors.white} />
              </$MenuSubMenu>
            );
            break;

          case MENU_ITEM_TYPES.BACK:
            component = (
              <$MenuBack
                onClick={menuItem.onClick}
                type={BUTTON_TYPES.BUTTON}
                data-testid={
                  dataTestId
                    ? `${dataTestId}_menu_child_${MENU_ITEM_TYPES.BACK}`
                    : `menu_child_${MENU_ITEM_TYPES.BACK}`
                }
                $isNewUiEnabled={isNewUiEnabled}
              >
                <$MenuContent>
                  <ChevronLeftIcon size="32px" color={theme.colors.white} />
                  <SafeFormattedMessageWithoutSpread message={messages.back} />
                </$MenuContent>
              </$MenuBack>
            );
            break;

          default:
            component = (
              <$MenuButton
                onClick={(e) => {
                  const response = { ...menuItem };

                  if (menuItem.onClick) {
                    menuItem.onClick(response, e);
                  }
                }}
                type={BUTTON_TYPES.BUTTON}
                ref={menuItem.ref}
                data-testid={menuItem.dataTestId || 'menu_child_button'}
                disabled={menuItem.isDisabled}
                $color={menuItem.color}
                $isNewUiEnabled={isNewUiEnabled}
              >
                <$MenuContent>
                  {menuItem.isLoading ? (
                    <LoaderIcon
                      size="32px"
                      color={
                        isNewUiEnabled
                          ? theme.colors.mainTextColor
                          : theme.colors.white
                      }
                    />
                  ) : (
                    menuItem.icon
                  )}
                  <$MenuTextContainer>
                    {SafeFormattedMessage(menuItem.label)}
                    {menuItem.description &&
                      SafeFormattedMessage(menuItem.description)}
                  </$MenuTextContainer>
                </$MenuContent>
              </$MenuButton>
            );
        }

        return (
          <$MenuItemStyled
            key={menuItem.key}
            data-testid={`menu_${menuItem.key}`}
            animationDuration={
              disabledAnimation ? 0 : VERTICAL_MENU_ANIMATION_DURATION
            }
            animationDelay={
              disabledAnimation ? 0 : calculateEasing(index, list.length)
            }
          >
            {component}
          </$MenuItemStyled>
        );
      })}
    </MenuAnimator>
  );
};

VerticalMenuContent.displayName = 'VerticalMenuContent';

export default VerticalMenuContent;
